import { createCustomEvent } from '../functions/core';
import { Notification } from '../types';

/**
 * Function that throws error event and passes the QubError object
 * @param error QubError object
 */
 export async function raiseNotification (params: Notification) {
    try{
        const {
            error,
            severity,
            ...rest
        } = params;

        const typeOfEvent = createCustomEvent(severity, {
            error,
            message: error?.message,
            ...rest
        });

        if(typeOfEvent && error)
            document.dispatchEvent(typeOfEvent);
    }
    catch(error) {
        console.error('Raise notification error', error)
        throw(error);
    }
}