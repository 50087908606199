/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import '@grapecity/wijmo.styles/wijmo.css';
import React from 'react';
import * as wjInput from '@grapecity/wijmo.react.input';
import { useFormContext } from 'react-hook-form';

type Props<TData> = {
    data: TData[],
    placeholder: string,
    showSelectAllCheckbox?: boolean,
    showFilterInput?: boolean,
    name: string,
    displayMemberPath: string,
    filterInputPlaceholder?: string
}

const MultiSelectDropdown = <TData,>({ placeholder, data, displayMemberPath, filterInputPlaceholder, showFilterInput = true, showSelectAllCheckbox = true, name }: Props<TData>) => {
    const { register, setValue } = useFormContext();

    // const [checked, setChecked] = React.useState<TData[]>(data);

    const onCheckedItemsChanged = ({ checkedItems }: { checkedItems: TData[] }) => {
        // Manually set value
        setValue(name, checkedItems);
    };

    React.useEffect(() => {
        // Manually register input
        register(name);
    }, []);

    return (
        <div className="form-group">
            <wjInput.MultiSelect
                filterInputPlaceholder={filterInputPlaceholder}
                maxDropDownWidth={1000}
                maxDropDownHeight={300}
                placeholder={placeholder}
                displayMemberPath={displayMemberPath}
                showSelectAllCheckbox={showSelectAllCheckbox}
                showFilterInput={showFilterInput}
                // checkedItems={checked}
                itemsSource={data}
                checkOnFilter={false}
                checkedItemsChanged={onCheckedItemsChanged}
            />
        </div>
    );
};

export default MultiSelectDropdown;
