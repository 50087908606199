import React from 'react';
import { Box, BoxProps } from '@mui/material';

export type SpiceProps = BoxProps & {
    color: string
}

const Spice = ({ color = '#2196f3', ...rest }: SpiceProps) => <Box sx={{ width: 5, height: 'inherit', background: color }} {...rest} />;

export default Spice;
