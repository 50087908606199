export const mapOperators = {
    0: 'equal',
    1: 'notEqual',
    2: 'greaterThan',
    3: 'greaterThanOrEqual',
    4: 'lessThan',
    5: 'lessThanOrEqual',
    6: 'custom', // Overriding wijmo's beginsWith to be custom
    7: 'endsWith', // Currently not included
    8: 'contains',
    9: 'notContains',
    10: 'custom'
}