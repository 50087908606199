import React from "react";
import { Typography } from "@mui/material";

export interface withSkeletonProps {
    status: 'loading' | 'success' | 'error'
}

export function withSkeleton<T extends withSkeletonProps>(
    WrappedComponent: React.ComponentType<T>, 
    SkeletonComponent: React.ComponentType<T>
) {

    const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithSkeleton = (props: T) => {
        const { status } = props;
        if (status === 'success') {
            return <WrappedComponent {...(props as T)} />;    
        }
        if (status === 'loading') {
            return <SkeletonComponent {...(props as T)}/>;
        }
        if (status === 'error') {
            return <Typography variant="subtitle1">There was an error fetching your data :(</Typography>;
        }
        return null;
    }

    ComponentWithSkeleton.displayName = `withSkeleton(${displayName})`;

    return ComponentWithSkeleton;
}