import { Enums, Exceptions } from "../../models";

/**
 * Map Qubiteq filter operators with Wijmo filter operators
 * @param wjOperator Wijmo filter operator
 * @returns Qubiteq filter operator
 */
 export const qubOperator = (wjOperator: keyof typeof Enums.mapOperators) =>{
    return Enums.mapOperators[wjOperator];
}

/**
 * Check if object is instance of QubError
 * @param candidate object to check
 * @returns true if @candidate instanceof QubError
 */
export function isQubError(candidate: any): candidate is Exceptions.QubError {
    return candidate.isQubError === true;
}

