
const createCustomEvent = (eventName: string, additionalData?: {[key: string]: any}): CustomEvent | undefined  => {
    if(window) {
        return new CustomEvent(eventName, {
            bubbles: true,
            detail: {
                ...additionalData
            }
        })
    } else {
        return undefined;
    }
}

export default createCustomEvent