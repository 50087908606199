import React from 'react';
import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { DynamicField } from './DynamicField';


export type DynamicFormProps = {
  form: any,
  methods: any,
  allowed?: boolean
}

/**
 * 
 * @param {object} form 
 * Dynamic form object 
 * @param {object } methods
 * React hook forms methods 
 * @param {boolean} allowed
 * Set to false if you want to disable the form
 * @example
 * // Simple Example
 * const form = {
*      {
          "id": 29,
          "name": "LastName",
          "value": "Test",
          "label": "Επώνυμο",
          "type": "string",
          "isReadOnly": null,
          "options": null,
          "gridSizes": {
              xs: 12,
              md: 12
          },
      },
    }
    const methods = useForm();
    ...
    return <DynamicForm form={test.array} methods={form} />; 
 */
const DynamicForm = ({ form, methods, allowed }: DynamicFormProps) => (
  <FormProvider {...methods}>
    <Grid container spacing={2}>
      {form.map((field: any) => {
        if (allowed !== undefined) {
          field.isReadOnly = !allowed || (allowed && field.isReadOnly)
        }
        return (
          <Grid key={field.id} item xs={field.gridSizes?.xs ?? 12} md={field.gridSizes?.md ?? 6} >
            <DynamicField {...field} />
          </Grid>
        )
      })}
    </Grid>
  </FormProvider>
);

export default DynamicForm;
