import { useContext, useEffect } from "react";
import { QubFileGroup, TQubFileGroup } from "./qubFileGroup";
import { QubFile, TQubFile } from "./qubFile";
import { ctx } from "./fileContext";
import { FormField } from "types";
import * as utils from "lodash";

export const useFileClient = (defaultFileGroups: TQubFileGroup[] | null) => {
  const { fileGroups, update } = useContext(ctx);

  useEffect(() => {
    if (!utils.isNil(defaultFileGroups)) {
      init(defaultFileGroups);
    }
  }, [defaultFileGroups]);

  const init = (fileGroups: TQubFileGroup[]) =>
    update(fileGroups.map((group) => new QubFileGroup(group)));

  const addFileGroup = (group: TQubFileGroup) =>
    update((prev) => [...prev, new QubFileGroup(group)]);

  const addfile = (file: TQubFile, groupId: number | string): void => {
    update((prev) =>
      prev.map((group) => {
        if (group.id === groupId) {
          group.addFile(new QubFile(file));
        }
        return group;
      })
    );
  };

  const getFile = (id: string | number): QubFile | undefined => {
    for (var group of fileGroups) {
      const file = group.getFile(id);
      if (file !== undefined) return file;
    }
    return undefined;
  };

  const removeFile = (id: string | number): void =>
    update((groups) =>
      groups?.map((group) => {
        group.removeFile(id);
        return group;
      })
    );

  const removeGroup = (id: string | number): void =>
    update((prev) => prev.filter((group) => group.id != id));

  const getFileGroups = (): QubFileGroup[] => fileGroups;

  const getFiles = (): QubFile[] =>
    fileGroups.reduce<QubFile[]>((prev, curr) => [...prev, ...curr.files], []);

  const getGroupFormFields = (id: string | number): FormField[] | undefined => {
    const group = fileGroups.find((group) => group.id === id);
    if (group === undefined) {
      return undefined;
    }
    return group.formFields;
  };

  return {
    fileGroups,
    init,
    getFileGroups,
    getGroupFormFields,
    addFileGroup,
    addfile,
    getFile,
    removeFile,
    removeGroup,
    getFiles,
  };
};
