import React from 'react';

const CRUDPanel = (props: { editing: boolean, toggleEditing: Function, commitChanges: Function, revertChanges: Function }) => {
    const {
        editing,
        toggleEditing,
        revertChanges,
        commitChanges
    } = props;

    return (
        <div className="Qub-DatagridCRUDContainer">
            {!editing ? (
                <button 
                    onClick={() => { console.log(toggleEditing, editing); toggleEditing(true); }} 
                    className="Qub-DatagridCRUDButton">
                    Edit
                </button>
            ) : (
                <>
                    <button 
                        onClick={() => { commitChanges(); toggleEditing(false); }} 
                        className="Qub-DatagridCRUDButton">
                        Save
                    </button>
                    <button 
                        onClick={() => { revertChanges(); toggleEditing(false); }} 
                        className="Qub-DatagridCRUDButton">
                        Cancel
                    </button>
                </>
            )}
        </div>
    )
}

export default CRUDPanel;