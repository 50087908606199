import * as React from 'react';
import { debounce } from 'lodash';

const useKeyboardActions = (gridProps: any, options: any): void => {
    const registeredEvents = React.useRef(false);

    const actions = debounce((e: any) => {
        switch(e.key) {
            /* Start editing on F2 */
            case 'F2':
                if(!gridProps.editing) gridProps.setEditing(true);
                break;

            // case 'Escape':
            //     console.log('You pressed', e.key);
            //     if(gridProps.editing) gridProps.setEditing(false);
            //     break;

            case 's':
            case 'S':
            case 'σ':
            case 'Σ':
                if (e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    gridProps.submitChanges();
                    gridProps.setEditing(false);
                }
                break;
        };

    }, 150);

    const cancelBrowserDefault = (e: any) => {
        if((e.key === 's' || e.key === 'S' || e.key === 'Σ' || e.key === 'σ') && e.ctrlKey){
            e.preventDefault();
            e.stopPropagation();
        }
    };

    React.useEffect(() => {
        const grid = gridProps.getGrid();
        if (options.crud && grid) {
            grid?.hostElement?.addEventListener('keydown', cancelBrowserDefault);
            grid?.hostElement?.addEventListener('keydown', actions);
            registeredEvents.current = true;
        };
        
        return () => {
            if (registeredEvents.current) {
                grid?.hostElement?.removeEventListener('keydown', cancelBrowserDefault);
                grid?.hostElement?.removeEventListener('keydown', actions);
            }
        }
    }, [gridProps])
}

export default useKeyboardActions;