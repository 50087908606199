import React from 'react';
import { Dropdown, TextInput, DatePicker, Checkbox, DateTimePicker} from '../InputFields';
import { BaseFormComponentProps } from '../../types';

export type DynamicFieldProps = BaseFormComponentProps & {
    id: any,
    value: any,
    type: 'string' | 'boolean' | 'date' | 'dropdown',
    important?: boolean,
    isReadOnly?: boolean,
    gridSizes?: {
      xs: number,
      md: number
    }
  } & any;
  
  export const DynamicField = (props: DynamicFieldProps) => {
    const { name, type, isReadOnly, value, label, id, important, gridSizes, ...rest } = props;
    let correctValue = value;



    switch (type) {
      case 'string':
        return (
          <TextInput
            id={`${name}-${id}`}
            name={name}
            label={label}
            disabled={isReadOnly}
            important={important}
            defaultValue={value}
            variant='outlined'
            {...rest}
          />
        );
      case 'date':
        return (
          <DatePicker
            id={`${name}-${id}`}
            name={name}
            label={label}
            important={important}
            disabled={isReadOnly}
            defaultValue={value}
            {...rest}
          />
        );
      case 'datetime':
        return (
          <DateTimePicker
            id={`${name}-${id}`}
            name={name}
            label={label}
            important={important}
            disabled={isReadOnly}
            defaultValue={value}
            {...rest}
          />
        )
      case 'boolean':
        if (typeof value === 'string') correctValue = value === '1' || value === 'true';
        return (
          <Checkbox
            id={`${name}-${id}`}
            name={name}
            label={label}
            important={important}
            disabled={isReadOnly}
            defaultValue={correctValue}
            {...rest}
          />
        );
      case 'dropdown':
        return (
          <Dropdown
            id={`${name}-${id}`}
            name={name}
            important={important}
            label={label}
            disabled={isReadOnly}
            defaultValue={value}
            {...rest}
          />
        );
      default:
        return null;
    }
  };