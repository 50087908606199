import React, { useEffect, useRef } from 'react';
import { showPopup, hidePopup, hasClass } from '@grapecity/wijmo';
import { GridColumn } from '../../types';

const useColumnPicker = (gridProps: any, listBoxRef: React.MutableRefObject<any>) => {
    const registeredHandlers = useRef<boolean>(false);

    useEffect(() => {
        const grid = gridProps.getGrid();
        
        /* Add handlers in grid and itemsSource in list */
        if (gridProps.status === 'success' && listBoxRef.current && !registeredHandlers.current) {
            grid.formatItem.addHandler((s: any, e: any) => {
                if (e.panel == s.topLeftCells) {
                    e.cell.innerHTML = '<span class="column-picker-icon wj-glyph-pencil"></span>';
                }
            });
            // show the column picker when the user clicks the top-left cell
            let ref = grid.hostElement.querySelector(".wj-topleft");
            ref.addEventListener("mousedown", (e: any) => {
                if (hasClass(e.target, "column-picker-icon")) {
                    let host = listBoxRef?.current?.hostElement;
                    if (!host.offsetHeight) {
                        showPopup(host, ref, false, true, false);
                        listBoxRef?.current?.focus();
                    }
                    else {
                        hidePopup(host, true, true);
                        grid.focus();
                    }
                    listBoxRef?.current?.focus();
                    e.preventDefault();
                }
            });
            registeredHandlers.current = true;

            const columnLayout = JSON.parse(grid.columnLayout).columns;
            const pickableHeader = grid.collectionView.header.filter((col: GridColumn) => col.isPickable);
            listBoxRef.current.itemsSource = pickableHeader.map((col: any) => {
                const visibility = columnLayout.find((element: any) => element.binding === col.binding).visible;
                return { 
                    ...col, 
                    visible: visibility || visibility === undefined
                }
            }); // Update picked columns

            listBoxRef.current.checkedMemberPath = 'visible';
            listBoxRef.current.displayMemberPath = 'header';
            listBoxRef.current.itemChecked.addHandler((listBox: any) => {
                const currentCol = grid.getColumn(pickableHeader[listBox.selectedIndex].binding);
                currentCol.visible = !currentCol.visible;
            });
            listBoxRef.current.lostFocus.addHandler(() => {
                hidePopup(listBoxRef?.current?.hostElement);
            });
        }
    }, [gridProps.status]);
};

export default useColumnPicker;