import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const DynamicFormSkeleton = () => (
    <Grid container spacing={2}>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((row) => (
            <Grid key={row} item xs={6}>
                <Skeleton variant="rectangular" width="100%" height={30} style={{ borderRadius: 12 }} />
            </Grid>
        ))}
    </Grid>
);

export default DynamicFormSkeleton;
