import { FormField, FileValidation, FileMetaData } from "types";
import { QubFile, TQubFile } from "./qubFile";
import utils from "lodash";

/**
 * Class representing a group of files
 */
export class QubFileGroup {
  constructor({
    id,
    files,
    name,
    description,
    formFields,
    fileValidation,
    fileMetaData,
    templates,
    other,
  }: TQubFileGroup) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.fileMetaData = fileMetaData;
    this.files = files?.map((file) => new QubFile(file)) ?? [];
    this.fileValidation = fileValidation ?? defaultFileValidationValues;
    this.formFields = formFields ?? [];
    this.other = other;
    this.templates = templates;
  }

  public id: string | number;
  public name: string;
  public description: string;
  public files: QubFile[];
  public fileValidation: FileValidation;
  public formFields: FormField[];
  public fileMetaData?: FileMetaData;
  public templates?: TTemplate[];
  public other: any;

  /**
   * Adds file to group
   */
  addFile(file: QubFile): void {
    if (utils.isNil(this.fileValidation.max)) {
      this.files.unshift(file);
      return;
    }
    if (this.files.length < this.fileValidation.max) {
      this.files.unshift(file);
      return;
    }
  }

  /**
   * Removes file from group given id
   * @param id
   */
  removeFile(id: string | number): void {
    this.files = this.files.filter((file) => file.id !== id);
  }
  /**
   * Gets file from group given id
   * @param id
   * @returns
   */
  getFile(id: string | number): QubFile | undefined {
    return this.files?.find((file) => file.id === id);
  }
}

export const defaultFileValidationValues = {
  max: Infinity,
};

export type TQubFileGroup = {
  id: string | number;
  name: string;
  description: string;
  files?: TQubFile[];
  formFields?: FormField[];
  fileValidation?: FileValidation;
  fileMetaData?: FileMetaData;
  other?: any;
  templates?: TTemplate[];
};

type TTemplate = {
  title: string;
  filename: string;
  path: string;
};
