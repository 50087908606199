import React, { createContext } from "react";

type GridPropsProviderProps = {
    gridProps: any,
    children: React.ReactNode;
  };

const Context = createContext<any>(undefined);

function useGridProps() {
    const gridProps = React.useContext(Context);
    if (gridProps === undefined) {
        throw new Error('useGridProps should be used within a GridPropsProvider');
    }
    return gridProps;
};

const GridPropsProvider = React.memo(({ gridProps, children }: GridPropsProviderProps) => {
    return <Context.Provider value={gridProps}>{children}</Context.Provider>;
});

export { useGridProps, GridPropsProvider };