import React from 'react';
import { Checkbox, FormControlLabel, Typography, Stack, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { useFormContext, useController } from 'react-hook-form';
import { BaseFormComponentProps } from '../../types';
import { formatLabel } from './shared/formatLabel';

export type CheckboxProps = BaseFormComponentProps & MuiCheckboxProps;

const FormCheckbox = ({ name, label, defaultValue, important, ...other }: CheckboxProps) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({ name, control, defaultValue });

  

  return (
    <Stack>
      <FormControlLabel
        control={
          /* eslint-disable */
          <Checkbox
            checked={field.value}
            onChange={field.onChange}
            name={field.name}
            {...other}
          />
        }
        label={
          <Typography variant="subtitle1" sx={{ userSelect: 'none' }}>
            {typeof label === "string" ? formatLabel(important, label) : label}
          </Typography>
        }
      />
      {!!fieldState.error && (
        <Typography variant="inherit" sx={{ color: '#f44336', fontWeight: 'bold' }}>{fieldState.error?.message}</Typography>
      )}
    </Stack>
  );
};

export default FormCheckbox;
