import { FormFieldValues } from "types";

/**
 * Class representing a file
 */
export class QubFile {
  constructor({ id, name, type, file, values }: TQubFile) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.file = file;
    this.values = values;
  }
  public id: string | number;
  public name: string;
  public type: TFileExtenstion;
  public file?: File;
  public values?: FormFieldValues;

  public setValue(name: string, value: string) {
    if (this.values) {
      this.values[name] = value;
    }
  }
}

export type TQubFile = {
  id: string | number;
  name: string;
  type: TFileExtenstion;
  file?: File;
  values?: FormFieldValues;
};

export type TFileExtenstion = "pdf" | "png" | "jpeg" | "jpg";
