export default class QubEvent {
    _handlers: Function[] = [];
    _grid?: any;

    constructor(grid: any) {
        this._grid = grid;
    }

    addHandler = (handler: Function) => {
        this._handlers.push(handler);
    };

    emit = (customData: Object) => {
        this._handlers.forEach((f: Function) => {
            f(this._grid, customData);
        })
    }
}