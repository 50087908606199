import React from 'react';
import { Box } from '@mui/material';

export type RoundSpiceProps = {
    color: string
}

const RoundSpice = ({ color = '#2196f3' }: RoundSpiceProps) => <Box sx={{ width: 8, height: 8, borderRadius: 4, background: color }} />;

export default RoundSpice;
