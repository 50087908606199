import { Menu } from '@grapecity/wijmo.input';
import { GroupRow } from '@grapecity/wijmo.grid';
import { CollectionViewGroup, PropertyGroupDescription } from '@grapecity/wijmo';
import { isFunction } from 'lodash';
import QubCollectionView from '../QubCollectionView';

export class FlexGridContextMenu {
    _items: any

    constructor(grid: any, items: any) {
        this._items = items;
        let host = grid.hostElement, menu = this._buildMenu(grid);
        host.addEventListener('contextmenu', (e: any) => {
            // select the cell/column that was clicked
            const sel = grid.selection, ht = grid.hitTest(e), row = ht.getRow();
            switch (ht.panel) {
                case grid.cells:
                    let colIndex = ht.col;
                    // if this is a group header, select the group column
                    if (row instanceof GroupRow && row.dataItem instanceof CollectionViewGroup) {
                        let gd = row.dataItem.groupDescription;
                        if (gd instanceof PropertyGroupDescription) {
                            let col = grid.getColumn(gd.propertyName);
                            if (col && col.index > -1) {
                                colIndex = col.index;
                            }
                        }
                    }
                    grid.select(ht.row, colIndex);
                    break;
                case grid.columnHeaders:
                    grid.select(sel.row, ht.col);
                    break;
                case grid.rowHeaders:
                    grid.select(ht.row, sel.col);
                    break;
                default:
                    return; // invalid panel
            }
            // show the menu for the current column
            if (grid.selection.col > -1) {
                e.preventDefault(); // cancel the browser's default menu
                menu.show(e); // and show ours
            }
        }, true);
    }
    _buildMenu(grid: any) {
        let menu = new Menu(document.createElement('div'), {
            owner: grid.hostElement,
            displayMemberPath: 'header',
            subItemsPath: 'items',
            commandParameterPath: 'cmd',
            dropDownCssClass: 'ctx-menu',
            openOnHover: true,
            closeOnLeave: true,
            itemsSource: this._items,
            command: {
                // enable/disable menu commands
                canExecuteCommand: (cmd: string) => {
                    // let view = grid.collectionView, col = grid.columns[grid.selection.col];
                    const view: QubCollectionView = grid.collectionView;

                    switch (cmd) {
                        case 'SAVE_STATE':
                            return isFunction(view._saveState);
                        case 'ASZ_ALL':
                            return true;
                    }
                    return true;
                },
                // execute menu commands
                executeCommand: (cmd: any) => {
                    const view = grid.collectionView;
                    switch (cmd) {
                        case 'SAVE_STATE':
                            view.saveState();
                            break;
                        case 'ASZ_ALL':
                            grid.autoSizeColumns(0, grid.columns.length - 1);
                            break;
                    }
                    // restore focus to active grid cell (TFS 439964)
                    grid.refresh();
                    let sel = grid.selection, cell = grid.cells.getCellElement(sel.row, sel.col);
                    if (cell) {
                        cell.focus();
                    }
                }
            }
        });
        // done
        return menu;
    }
}
