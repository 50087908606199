export class QubError extends Error {
    error: any;
    origin: string;
    type: any;

    constructor(message: string, error: any, origin: string, type: any) {
        super(message);

        Error.captureStackTrace(this, QubError);

        this.error = error;
        this.origin = origin;
        this.name = 'QubError';
        this.type = type;
    }
}