import React from "react";
// mui
import { TextField, Typography, Stack, TextFieldProps } from "@mui/material";

// hook forms
import { useFormContext } from "react-hook-form";

import { useEffect } from "react";
import { BaseFormComponentProps } from "../../types";
import { formatLabel } from "./shared/formatLabel";

export type TextInputProps = BaseFormComponentProps &
  Omit<TextFieldProps, keyof BaseFormComponentProps>;

const TextInput = (props: TextInputProps) => {
  const { register, formState, setValue } = useFormContext();
  const { errors } = formState;
  const { name, rules, label, defaultValue, important, ...other } = props;


  useEffect(() => {
    setValue(name, defaultValue);
  }, [setValue, name, defaultValue]);




  return (
    <Stack sx={{ width: "100%"}}>
      {typeof label === "string" ? <Typography>{formatLabel(important, label)}</Typography> : label}
      <TextField
        sx={{
          borderColor: "red",
        }}
        inputProps={{
          ...register(name, rules),
        }}
        InputLabelProps={{ shrink: true }}
        error={!!errors[name]}
        helperText={errors[name]?.message}
        required={rules?.required}
        size="small"
        fullWidth
        {...other}
      />
    </Stack>
  );
};

export default TextInput;
