import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import { QubFileGroup } from "./qubFileGroup";

export function createCtx<A>(defaultValue: A) {
    type UpdateType = Dispatch<SetStateAction<typeof defaultValue>>;
    const defaultUpdate: UpdateType = () => defaultValue;
    const ctx = createContext({
        fileGroups: defaultValue,
        update: defaultUpdate,
    });

    function Provider(props: PropsWithChildren<{}>) {
        const [fileGroups, update] = useState(defaultValue);
        return <ctx.Provider value={{ fileGroups, update }} {...props} />
    }
    return [ctx, Provider] as const;
}

export const [ctx, FileProvider] = createCtx<QubFileGroup[]>([]);

