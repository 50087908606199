/**
 * This is a function called everytime a bringValue event is called on cellEditEnding event
 * @param grid FlexGrid wijmo object
 * @param e Bring Value event object
 */
export const bringValue = async (grid: any, e: any) => {
    const view = grid.collectionView;
    const column = view.getColumnByBinding(grid.columns[e.col].binding);

    if (column.isBringValue && view.bringValueProps.isBringValue && grid.activeEditor) {
        /* Stay in edit mode */
        e.cancel = true;
        const value = grid.activeEditor?.value || '';

        /**
         * Construct filter
         */
        const filter = [{
            searchField: column.bringValueField,
            filter: value || '',
            operation: 'custom'
        }];

        /* Set coordinates of the cell which caused bring value */
        view.bvCoordinates = {
            row: e.row,
            col: e.col
        };

        // view.bringValueRunning = true;
        // e.stayInEditMode = true;
        const result = await view.bringValueProps.bringValue({ grid, e}, column.bringValueTable, filter)
        if(!result){
            // grid.collectionView.bringValueRunning = true
            // grid.setCellData(e.row, e.col, null);

            /* Clear all columns depending on this value */
            view.clearBvDependents(column.bringValueTable, e.row);
            grid.finishEditing(true);
        }
    } else {
        e.cancel = false;
    }

    if(column.isBringValue && view.bringValueRunning){
        // view.bringValueRunning = false
    }
}